<template>
  <v-container>
    <v-row class="mb-4">
      <h2>Freezing Point Depression: Report Sheet</h2>
    </v-row>

    <v-row class="mb-2">
      a) Complete the data table below using data collected during the experiment and values
      calculated therefrom. Assume a density of 1.00 g/mL for pure water.
    </v-row>

    <v-simple-table class="mb-8" style="max-width: 529px">
      <thead>
        <tr>
          <td style="font-weight: bold">Property</td>
          <td style="font-weight: bold; text-align: center">Value</td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Mass of water (g)</td>
          <td>
            <calculation-input
              v-model="inputs.massWater"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>Mass of unknown (g)</td>
          <td>
            <calculation-input
              v-model="inputs.massUnknown"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td><stemble-latex content="$\text{T}_\text{f}~$" /> of pure water (°C)</td>
          <td>
            <calculation-input
              v-model="inputs.tfPure"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td><stemble-latex content="$\text{T}_\text{f}~$" /> of unknown solution (°C)</td>
          <td>
            <calculation-input
              v-model="inputs.tfSolution"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td />
          <td />
        </tr>

        <tr>
          <td><stemble-latex content="$\Delta\text{T}_\text{f}~$" /> (°C)</td>
          <td>
            <calculation-input
              v-model="inputs.deltaTf"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>Molality (mol/kg)</td>
          <td>
            <calculation-input
              v-model="inputs.molality"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>Moles of unknown (mol)</td>
          <td>
            <calculation-input
              v-model="inputs.molUnknown"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>Molar mass of unknown (g/mol)</td>
          <td>
            <calculation-input
              v-model="inputs.molarMassUnknown"
              class="my-1 centered-input"
              :disabled="!allowEditing"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <p class="mb-2">
      b) Please upload all graphs and data for this experiment in a single file using the input
      field below.
    </p>

    <p><b>Accepted file types</b>: PDF, JPG, PNG, XLSX, XLS</p>

    <v-row class="mb-5 pl-4">
      <v-file-input v-model="dataUpload" multiple />
    </v-row>

    <v-row class="mb-2">
      c) In the space below, discuss your results and potential sources of error for the experiment.
    </v-row>

    <v-row class="mb-5">
      <s-textarea v-model="inputs.sourcesOfError" outlined :counter="true" :maxlength="1500" />
    </v-row>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'FreezingPointDepressionWaterReportSheet',
  components: {
    STextarea,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        unknownNumber: null,

        massWater: null,
        massUnknown: null,
        tfPure: null,
        tfSolution: null,

        deltaTf: null,
        molality: null,
        molUnknown: null,
        molarMassUnknown: null,
        pctRecovered: null,

        sourcesOfError: null,
      },
      dataUpload: [],
    };
  },
  computed: {
    attachments() {
      return [...this.dataUpload];
    },
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
